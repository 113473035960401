const anchors = document.querySelectorAll('a[href*="#"]')

let offset = matchMedia('(max-width: 992px)').matches ? 70 : 140

anchors.forEach((anchor) => {
  anchor.addEventListener('click', (e) => {
    e.preventDefault()
    const scrollBlockID = anchor.getAttribute('href')

    const y = document.querySelector('' + scrollBlockID).getBoundingClientRect().top + window.pageYOffset - offset;

    window.scrollTo({
      top: y,
      left: 0,
      behavior: 'smooth',
    })
  })
})

window.addEventListener('resize', () => {
  offset = matchMedia('(max-width: 992px)').matches ? 70 : 140
})
