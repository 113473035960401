const burgerBtn = document.querySelector('.burger_btn')
const burgerBtnClose = document.querySelector('.burger_btn_close')
const burger = document.querySelector('.burger')

burgerBtn.addEventListener('click', () => {
  burger.classList.add('active')
})

burgerBtnClose.addEventListener('click', () => {
  burger.classList.remove('active')
})


burger.addEventListener('click', (e) => {
  console.log(e.target)

  if (e.target.classList.contains('burger')) {
    burger.classList.remove('active')
  }
})

