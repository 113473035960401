export const Modal = () => {
  const allModalOpenButton = document.querySelectorAll('button[data-modal]');

  allModalOpenButton.forEach((btn) => {
    btn.addEventListener('click', () => {
      const modal = document.querySelector(`#${btn.dataset.modal}`)

      modal.classList.add('modal__active')

      modal.querySelector('button[data-close]').addEventListener('click', () => {
        modal.classList.remove('modal__active')
      })

      modal.addEventListener('click', (e) => {
        if (e.target.id === btn.dataset.modal) {
          modal.classList.remove('modal__active')
        }
      })
    })
  })
}

Modal()



