import './_vendor';
import vars from './_vars';
import './_functions';
import './_components';

import lightGallery from  'lightgallery'

import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

const gallery = document.getElementById('lightgallery')

lightGallery(gallery, {
  plugins: [lgZoom, lgThumbnail],
  speed: 500,
  licenseKey: 'your_license_key',
});

const gallery1 = document.getElementById('lightgallery1')

lightGallery(gallery1, {
  plugins: [lgZoom, lgThumbnail],
  speed: 500,
  licenseKey: 'your_license_key',
});

import './components/modal'
import './components/burger'
import './components/anchors'